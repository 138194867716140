<mat-toolbar
  *ngIf="data.mobileType === 'android'"
  color="primary"
  id="android-prompt"
>
  <button mat-button (click)="installPwa()">Add to Home screen</button>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<div *ngIf="data.mobileType === 'ios'" id="ios-prompt">
  <!-- <button id="close-ios-inv" mat-button color="primary" (click)="close()">
    <mat-icon>close</mat-icon>
  </button> -->
  <div>
    To install this web app on your device tap the Menu button and then 'Add to
    Home screen' button
  </div>
  <div id="icon-row">
    <div id="cell-div-1">
      <img id="cell-image-1" src="./assets/icons/prompt/ios-menu-btn.png" />
    </div>
    <mat-icon>navigate_next</mat-icon>
    <div id="cell-div-2">
      <img id="cell-image-2" src="./assets/icons/prompt/ios-add-btn.png" />
    </div>
  </div>
</div>
