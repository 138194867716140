import { Injectable, OnDestroy } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NetworkService implements OnDestroy {
  private readonly pollingInterval = 30000; // 30 seconds
  private readonly veryBadNetworkLatency = 3000; // Latency in ms
  private readonly veryBadNetworkBandwidth = 20; // Bandwidth in Kbps
  // private readonly veryBadNetworkBandwidth = 100000; // Bandwidth in kbps

  private onlineStatus$ = new BehaviorSubject<boolean>(true);
  private pollingSubscription: Subscription | null = null;
  private directHttpClient: HttpClient;
  private isCheckingNetwork = false; // Flag to prevent multiple bursts

  constructor(private http: HttpClient, private httpBackend: HttpBackend) {
    console.log("Network service initialized.");
    this.directHttpClient = new HttpClient(this.httpBackend);
    this.checkInternetConnection().catch((error) => {
      console.error("Error during initial connection check:", error);
    });
  }

  /**
   * Mark the internet as down and start polling.
   */
  public markInternetDown() {
    if (this.onlineStatus$.value) {
      this.onlineStatus$.next(false);
    }

    if (!this.pollingSubscription) {
      this.startPolling();
    }
  }

  public getOnlineStatus(): Observable<boolean> {
    return this.onlineStatus$.asObservable();
  }

  /**
   * Check if the application is online.
   */
  public isOnline(): boolean {
    return this.onlineStatus$.value;
  }

  /**
   * Externally callable method to check internet connection.
   */
  public async checkInternetConnection(): Promise<boolean> {
    if (this.isCheckingNetwork) {
      console.log("Network quality check is already in progress. Ignoring.");
      return this.onlineStatus$.value; // Return the current status
    }

    this.isCheckingNetwork = true; // Set the flag

    try {
      const qualityCheck = await this.checkNetworkQuality();

      if (!qualityCheck) {
        console.warn("Network quality check failed. Marking offline.");
        this.markInternetDown();
        return false;
      }

      this.onlineStatus$.next(true);
      return true;
    } catch (error) {
      console.error("Error during connection check:", error);
      this.markInternetDown();
      return false;
    } finally {
      this.isCheckingNetwork = false; // Reset the flag
    }
  }

  /**
   * Perform latency and bandwidth tests in a controlled burst.
   */
  private async checkNetworkQuality(): Promise<boolean> {
    if (!this.directHttpClient || !this.http) {
      console.log(
        "HTTP clients are unavailable. Skipping network quality check."
      );
      return false;
    }

    const healthCheckUrl =
      "https://api-dev.medicnowsolutions.com/api/healthcheck"; // Replace with your actual health check URL
    const fileDownloadUrl =
      "https://sandbox-medicnow-app.s3.us-east-1.amazonaws.com/test-file-256kb.bin"; // Your test file URL
    const fileSizeInBytes = 256 * 1024; // 256 KB file size

    try {
      // Step 1: Measure latency via multiple sequential health check requests
      const latencyTests = 3; // Number of latency tests
      let totalLatency = 0;

      for (let i = 0; i < latencyTests; i++) {
        const latencyStart = performance.now();
        try {
          await this.http
            .get(healthCheckUrl, { responseType: "text" })
            .toPromise();
        } catch (latencyError) {
          console.warn(`Latency Test ${i + 1} failed:`, latencyError);
          continue; // Skip to the next test if one fails
        }
        const latencyEnd = performance.now();
        const latency = latencyEnd - latencyStart;
        console.log(`Latency Test ${i + 1}: ${latency} ms`);
        totalLatency += latency;

        // Wait briefly before the next test (e.g., 100ms delay)
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      const averageLatency = totalLatency / latencyTests;
      console.log(
        `Network: Average Health Check Latency: ${averageLatency.toFixed(2)} ms`
      );

      // Step 2: Measure bandwidth via file download
      const downloadStart = performance.now();
      try {
        await this.directHttpClient
          .get(fileDownloadUrl, {
            headers: new HttpHeaders({
              "Cache-Control": "no-cache",
              Connection: "keep-alive",
            }),
            responseType: "blob",
            observe: "response",
          })
          .toPromise();
      } catch (downloadError) {
        console.error("File download failed:", downloadError);
        return false; // Treat as offline if download fails
      }
      const downloadEnd = performance.now();
      const downloadTimeInMillis = downloadEnd - downloadStart;

      console.log(
        `Network: File Download Time: ${downloadTimeInMillis.toFixed(2)} ms`
      );

      // Adjust download time for average latency
      const adjustedDownloadTimeInMillis =
        downloadTimeInMillis - averageLatency;

      console.log(
        "Network: Adjusted Download Time (substracted latency): ",
        adjustedDownloadTimeInMillis
      );

      if (adjustedDownloadTimeInMillis <= 0) {
        console.warn(
          "Network: Adjusted download time is less than or equal to 0. Skipping bandwidth calculation."
        );
        return true; // Network quality is acceptable
      }

      const adjustedDownloadTimeInSeconds = adjustedDownloadTimeInMillis / 1000;
      const bandwidth = 256 / adjustedDownloadTimeInSeconds; // Bandwidth in KBps

      console.log(`Network: Bandwidth: ${bandwidth.toFixed(2)} KBps`);

      // Step 3: Evaluate thresholds
      if (
        averageLatency >= this.veryBadNetworkLatency || // Threshold for latency
        bandwidth <= this.veryBadNetworkBandwidth // Threshold for bandwidth
      ) {
        console.warn(
          "Network: Network quality thresholds exceeded. Poor connection detected."
        );
        return false; // Network quality is poor
      }

      console.log("Network: Network quality is good.");
      return true; // Network quality is acceptable
    } catch (error) {
      console.error("Failed to evaluate network quality:", error);
      return false; // Treat as offline in case of error
    }
  }

  /**
   * Start polling to check for network reconnection.
   */
  private startPolling() {
    this.pollingSubscription = interval(this.pollingInterval).subscribe(() => {
      this.checkInternetConnection();
    });
  }

  /**
   * Stop polling when network is restored.
   */
  private stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = null;
    }
  }

  /**
   * Cleanup when service is destroyed.
   */
  ngOnDestroy() {
    this.stopPolling();
  }

  isNetworkError(error: any): boolean {
    if (error.status === 0) {
      return true;
    }

    const errorMessage = error.message || error.toString();
    if (
      errorMessage.includes("ERR_INTERNET_DISCONNECTED") ||
      errorMessage.includes("NetworkError") ||
      errorMessage.includes("Failed to fetch")
    ) {
      return true;
    }

    return false;
  }
}
