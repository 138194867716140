import { Component, Inject, OnInit } from "@angular/core";
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";

@Component({
  selector: "lib-success-notification",
  templateUrl: "./success-notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class SuccessNotificationComponent implements OnInit {
  message = "";

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { message: string },
    private bottomSheetRef: MatBottomSheetRef<SuccessNotificationComponent>
  ) {
    this.message = this.data.message;
  }

  ngOnInit(): void {
    this.bottomSheetRef.afterDismissed().subscribe(() => {});
  }

  public close() {
    this.bottomSheetRef.dismiss();
  }
}
