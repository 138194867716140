import { Platform } from "@angular/cdk/platform";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { timer } from "rxjs";
import { take } from "rxjs/operators";
import { FailureNotificationComponent } from "./failure-notification.component";
import { SuccessNotificationComponent } from "./success-notification.component";
import { UpdateNotificationComponent } from "./update-notification.component";

@Injectable({
  providedIn: "root",
})
export class UiNotificationService {
  private promptEvent: any;

  constructor(
    private bottomSheet: MatBottomSheet,
    private platform: Platform
  ) {}

  public initUpdateNotificationPrompt(version) {
    timer(1000)
      .pipe(take(1))
      .subscribe(() =>
        this.bottomSheet.open(UpdateNotificationComponent, {
          data: { version },
        })
      );
  }

  public successNotification(message) {
    timer(0)
      .pipe(take(1))
      .subscribe(() =>
        this.bottomSheet.open(SuccessNotificationComponent, {
          data: { message },
        })
      );
  }

  public failureNotification(message) {
    timer(0)
      .pipe(take(1))
      .subscribe(() =>
        this.bottomSheet.open(FailureNotificationComponent, {
          data: { message },
        })
      );
  }
}
