import { Location } from "@angular/common";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { interval, Subscription } from "rxjs";
import { UiNotificationService } from "../../features/ui-notification/ui-notification.service";
import { environment } from "../../../environments/environment";
import { SideNavigatorService } from "../../services/side-navigator.service";
import { NetworkService } from "src/app/services/network.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  intervalSubscription = null;
  updateSubscription = null;
  activatedSubscription = null;

  networkSubscription: Subscription | null = null;
  online = true;

  @Input() title: string;

  constructor(
    private location: Location,
    private router: Router,
    private updates: SwUpdate,
    public sideNavigatorService: SideNavigatorService,
    public notificationService: UiNotificationService,
    public networkService: NetworkService
  ) {
    if (updates.isEnabled) {
      this.intervalSubscription = interval(1000 * 60 * 60 * 24).subscribe(() =>
        updates.checkForUpdate().then(() => console.log("checking for updates"))
      );
    }

    this.networkSubscription = this.networkService
      .getOnlineStatus()
      .subscribe((isOnline) => {
        this.online = isOnline;
      });
  }

  ngOnInit(): void {
    this.updateSubscription = this.updates.versionUpdates.subscribe(
      (event: VersionReadyEvent) => {
        console.log(event);
        if (event.type === "VERSION_READY") {
          console.log("current version is", event.currentVersion);
          console.log("available version is", event.latestVersion);
          console.log("updating to latest available version");
          this.updates.activateUpdate().then(() => {
            this.notificationService.initUpdateNotificationPrompt(
              environment.timeStamp
            );
          });
        }
      }
    );
    this.updates.activateUpdate().then(
      (val) => console.log("new version installed: " + val),
      (err) => console.error(err)
    );
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
    if (this.activatedSubscription) {
      this.activatedSubscription.unsubscribe();
    }
  }

  goBack() {
    this.location.back();
  }

  isBackVisible(): boolean {
    //const id: Observable<string> = route.params.pipe(map(p => p.id));
    //const url: Observable<string> = route.url.pipe(map(segments => segments.join('')));
    return this.router.url !== "/" && this.router.url !== "/home";
  }

  isMenuVisible(): boolean {
    //const id: Observable<string> = route.params.pipe(map(p => p.id));
    //const url: Observable<string> = route.url.pipe(map(segments => segments.join('')));
    return true;
  }

  // @HostListener("window:mouseup", ["$event.target.id"])
  // onMouseDown(id: string) {
  //   var sideBarVisible =
  //     this.sideNavigatorService.getToggleClass() === "sidebar-visible";
  //   if (sideBarVisible && id != "sidebar" && id != "menu") {
  //     this.sideNavigatorService.toggleSideNavigator();
  //   }
  // }

  // @HostListener("window:touchend", ["$event.target.id"])
  // onTouchStart(id: string) {
  //   var sideBarVisible =
  //     this.sideNavigatorService.getToggleClass() === "sidebar-visible";
  //   if (sideBarVisible && id != "sidebar" && id != "menu") {
  //     this.sideNavigatorService.toggleSideNavigator();
  //   }
  // }
}
