<div id="react-native-root">
  <ng-container *ngIf="isDesktop">
    <!-- <app-sidebar docked="true"></app-sidebar> -->
  </ng-container>
  <app-header id="react-native-header" [title]="homeName" *ngIf="!isDesktop">
  </app-header>
  <div id="offline-watermark" *ngIf="!online">
    <span>Offline Or Slow Internet Speeds</span>
  </div>
  <div id="react-native-body-filler" *ngIf="!online"></div>
  <div id="react-native-body" *ngIf="online"></div>
  <app-footer [featureBaseUrl]="baseUrl" *ngIf="!isDesktop"></app-footer>
</div>
