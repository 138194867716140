import { NgModule } from "@angular/core";
import { UiNotificationComponent } from "./ui-notification.component";
import { FailureNotificationComponent } from "./failure-notification.component";
import { SuccessNotificationComponent } from "./success-notification.component";
import { UpdateNotificationComponent } from "./update-notification.component";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    UiNotificationComponent,
    FailureNotificationComponent,
    SuccessNotificationComponent,
    UpdateNotificationComponent,
  ],
  imports: [MatIconModule],
  exports: [
    UiNotificationComponent,
    FailureNotificationComponent,
    SuccessNotificationComponent,
    UpdateNotificationComponent,
  ],
})
export class UiNotificationModule {}
