import { Component, Inject, OnInit } from "@angular/core";
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";

@Component({
  selector: "lib-update-notification",
  templateUrl: "./update-notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class UpdateNotificationComponent implements OnInit {
  versionString = "";

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { version: string },
    private bottomSheetRef: MatBottomSheetRef<UpdateNotificationComponent>
  ) {
    this.versionString = this.data.version;
  }

  ngOnInit(): void {
    this.bottomSheetRef.afterDismissed().subscribe(() => {
      window.location.reload();
    });
  }

  public close() {
    this.bottomSheetRef.dismiss();
  }
}
